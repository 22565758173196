<template>
  <div v-for="(modal, index) in modals" :key="'modal-index-' + index">
    <div
      v-if="modal.isVisible"
      :style="{
        zIndex: 1056 + index * 2,
        '--cui-backdrop-zindex': 1056 + index * 2,
      }"
      class="modal-backdrop fade show"
    ></div>
    <CModal
      :size="modal.size"
      backdrop="static"
      :visible="modal.isVisible"
      aria-labelledby="CommonModalLabel"
      :style="{ zIndex: 1057 + index * 2 }"
      alignment="center"
      scrollable
      @close="handleClose($event, modal)"
    >
      <CModalHeader>
        <CModalTitle
          v-if="modal.titleHtml"
          id="CommonModalLabel"
          v-html="modal.titleHtml"
        />
        <CModalTitle v-else id="CommonModalLabel">{{
          modal.title
        }}</CModalTitle>
      </CModalHeader>
      <CModalBody v-html="modal.content"> </CModalBody>
      <CModalFooter v-if="modal.okButtonOnly">
        <CButton color="primary" @click="confirm">{{
          modal.okButtonName
        }}</CButton>
      </CModalFooter>
      <CModalFooter v-else>
        <CButton color="secondary" @click="handleClose($event, modal)">{{
          modal.okButtonHandler ? modal.cancelButtonName : modal.okButtonName
        }}</CButton>
        <CButton
          v-if="modal.okButtonHandler"
          color="primary"
          @click="confirm"
          >{{ modal.okButtonName }}</CButton
        >
      </CModalFooter>
    </CModal>
  </div>
</template>

<script setup>
import { CModal, CButton } from "@coreui/vue";
import { CModalFooter } from "@coreui/vue-pro/dist/esm/components/modal/index.js";
import { useModalStore } from "~/stores/modal";

// 상태 관리
const modalStore = useModalStore();
const modals = ref([]);

watch(
  () => modalStore.modals,
  (newVal) => {
    if (newVal) {
      modals.value = newVal;
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

// 모달 닫기 및 확인 버튼 로직
const handleClose = (e, modal) => {
  if (modal.okButtonOnly && modal.okButtonHandler) {
    modal.okButtonHandler($event);
  } else {
    modalStore.closeModal();
  }
};

const confirm = () => {
  modalStore.confirmModal();
};
</script>
